.right-content .chat-box,
.right-content .div-block-549,
.right-content #mentor-placeholder {
  height: 100%;
}
.average-graph-container .tp_content.sho {
  display: none;
}
.show.w-select {
  background: transparent
    url('data:image/svg+xml;charset=utf-8,<svg fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13.418 7.859a.695.695 0 0 1 .978 0 .68.68 0 0 1 0 .969l-3.908 3.83a.697.697 0 0 1-.979 0l-3.908-3.83a.68.68 0 0 1 0-.969.695.695 0 0 1 .978 0L10 11z"/></svg>')
    right -2px center no-repeat !important;
}

.callback-msg {
  font-size: 13px;
  padding: 8px 12px;
  margin-top: 15px;
  text-align: center;
  border-radius: 4px;
  display: none;
}

.callback-msg.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  display: block;
}

.callback-msg.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
  display: block;
}

.af-view .popup_div-nm_popup {
  position: fixed;
}

.af-view .div-block-183 {
  flex-wrap: wrap;
  gap: 20px;
}

.af-view .div-block-256.export_to_csv {
  margin-left: 20px;
}

.report-spinner {
  margin-left: auto;
}

.pagination-container ul {
  list-style-type: none;
}

.w_per .div-block-177 .div-block-203.ph {
  display: block;
  height: auto;
  height: 8px;
}
