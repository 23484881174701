.w-layout-hflex-error-ui {
  flex-direction: row;
  align-items: flex-start;
  display: flex;
}

.body {
  height: 100%;
  color: #000;
  font-family: Open Sans, sans-serif;
}

.error-page-main-container {
  width: 100%;
  height: 100%;
  justify-content: space-around;
  align-items: center;
}

.error-page-primary-heading {
  color: #2467eb;
  letter-spacing: 16px;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 130px;
  line-height: 100%;
}

.error-page-left-side {
  justify-content: center;
  align-items: center;
}

.error-page-right-side {
  height: 100%;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error-page-image-block {
  width: 350px;
  height: 350px;
  background-color: rgba(189, 189, 189, .35);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}

.error-page-image {
  width: 70%;
  opacity: .9;
}

.error-page-secondary-heading {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 45px;
  line-height: 100%;
}

.error-page-description {
  text-align: center;
  font-size: 17px;
  line-height: 25px;
}

.error-page-btn {
  width: auto;
  height: auto;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  color: #fff;
  cursor: pointer;
  background-image: linear-gradient(135deg, #2467eb, rgba(36, 103, 235, .64) 52%);
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  position: relative;
}

.error-page-btn:hover {
  background-color: #2467eb;
  background-image: none;
}

.btn-spinner-block {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.btn-spinner-block.hidden {
  display: none;
}

.error-page-btn-link {
  color: #fff;
  padding: 15px 20px;
  text-decoration: none;
}

.error-page-layout {
  width: 100%;
  height: 100%;
  grid-column-gap: 120px;
  grid-row-gap: 120px;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 991px) {
  .error-page-primary-heading {
    font-size: 90px;
  }

  .error-page-right-side {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }

  .error-page-image-block {
    width: 250px;
    height: 250px;
  }

  .error-page-secondary-heading {
    font-size: 30px;
  }

  .error-page-description {
    font-size: 14px;
  }

  .error-page-layout {
    grid-column-gap: 50px;
    grid-row-gap: 50px;
  }
}

@media screen and (max-width: 767px) {
  .error-page-right-side {
    height: auto;
    grid-column-gap: 25px;
    grid-row-gap: 25px;
  }

  .error-page-layout {
    grid-column-gap: 34px;
    grid-row-gap: 34px;
    flex-direction: column;
    justify-content: center;
  }
}

@media screen and (max-width: 479px) {
  .error-page-main-container {
    padding-left: 10px;
    padding-right: 10px;
  }

  .error-page-image-block {
    width: 230px;
    height: 230px;
  }
}
