.w-layout-vflex {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.w-layout-hflex {
  flex-direction: row;
  align-items: flex-start;
  display: flex;
}

.body {
  height: 100%;
  color: #000;
  font-family: Open Sans, sans-serif;
}

.auth-page-title {
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
}

.auth-page-title.smaller {
  font-size: 28px;
}

.password-eye-block {
  height: 55px;
  opacity: .5;
  cursor: pointer;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  justify-content: center;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: auto;
  right: 0%;
}

.password-eye-block:hover {
  opacity: .7;
}

.external-auth-btn {
  width: 100%;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  cursor: pointer;
  border: .8px solid #a7a7a7;
  border-radius: 4px;
  padding: 15px 20px;
}

.external-auth-btn:hover {
  background-color: rgba(237, 237, 237, .64);
}

.div-block-35 {
  width: 0;
  height: 15px;
  border: .5px solid #000;
}

.auth-footer-links {
  opacity: .6;
}

.auth-footer-links:hover {
  opacity: .85;
}

.auth-input-group {
  width: 100%;
  display: none;
  position: relative;
}

.auth-input-group.visible {
  display: flex;
}

.btn-spinner-block {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.btn-spinner-block.hidden {
  display: none;
}

.div-block-36 {
  width: 100%;
  border: .5px solid #a7a7a7;
}

.password-eye-icon {
  width: 20px;
}

.password-eye-icon.hidden {
  display: none;
}

.auth-footer {
  width: 100%;
  height: 30px;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: center;
  align-items: center;
}

.external-auth-label {
  opacity: .7;
}

.auth-page-block {
  width: 320px;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  overflow: auto;
}

.text-block-29 {
  opacity: .75;
}

.auth-page-main-content {
  width: 100%;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  align-items: center;
}

.auth-page-container {
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  text-decoration: none;
}

.link-block {
  color: #000;
  font-size: 12px;
  text-decoration: none;
}

.auth-logo-img {
  width: 40px;
}

.auth-input {
  height: 55px;
  border-radius: 4px;
  margin-bottom: 0;
  padding-left: 20px;
  font-size: 16px;
}

.auth-input.password-field {
  padding-right: 40px;
}

.auth-redirect-block {
  width: 100%;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: center;
  font-size: 13px;
}

.external-auth-block {
  width: 100%;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
}

.auth-seperation-block {
  width: 100%;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  justify-content: center;
  align-items: center;
}

.auth-form {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-direction: column;
  display: flex;
}

.image-31 {
  width: 20px;
}

.auth-form-block {
  width: 100%;
  margin-bottom: 0;
}

.auth-submit-btn {
  width: 100%;
  height: 55px;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  color: #fff;
  cursor: pointer;
  background-image: linear-gradient(135deg, #2467eb, rgba(36, 103, 235, .64) 52%);
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  position: relative;
}

.auth-submit-btn:hover {
  background-color: #2467eb;
  background-image: none;
}

.auth-link-redirect {
  opacity: .75;
  color: #2467eb;
  text-decoration: none;
}

.auth-link-redirect:hover {
  opacity: 1;
}

.auth-link-redirect.mt-small {
  margin-top: 20px;
}

.submit-button-2 {
  display: none;
}

.text-block-30 {
  opacity: .55;
  font-size: 12px;
}

.external-auth-container {
  width: 100%;
  color: #000;
  text-decoration: none;
}

.text-error {
  width: 100%;
  color: #fff;
  text-align: center;
  background-color: #ff4e4e;
  margin-top: -5px;
  padding: 2px 8px;
  font-size: 13px;
  font-weight: 300;
}

.text-error.hidden {
  display: none;
}

.notification-close-icon {
  width: 18px;
  opacity: .7;
}

.notifications-block {
  z-index: 2147483647;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-direction: column;
  padding: 15px;
  display: flex;
  position: fixed;
  top: 0%;
  bottom: 0%;
  left: auto;
  right: 0%;
}

.notification-close {
  cursor: pointer;
  border-radius: 3px;
  padding: 1px;
  position: absolute;
  top: 24px;
  bottom: auto;
  left: auto;
  right: 23px;
}

.notification-close:hover {
  background-color: rgba(30, 103, 235, .09);
}

.notification-header-title {
  color: #333;
  font-size: 16px;
}

.notification-body {
  margin-top: 8px;
  padding-left: 35px;
}

.notification-body-parag {
  color: #333;
  margin-bottom: 0;
  font-size: 14px;
}

.notification-header-block-icon {
  width: 20px;
}

.notification-header-block {
  width: 100%;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  align-items: center;
}

.notification-element {
  width: 100%;
  max-width: 420px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  background-color: #fff;
  border-radius: 5px;
  flex-direction: column;
  padding: 24px 25px 14px;
  display: none;
  position: relative;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
}

.div-block-37 {
  width: 100%;
  border: .5px solid #a7a7a7;
}

.text-block-31, .auth-link-redirect-2 {
  opacity: .75;
}

.auth-link-redirect-2:hover {
  opacity: 1;
}

.auth-title-desc {
  opacity: .7;
  text-align: center;
  font-size: 13px;
}

.text-block-32 {
  opacity: .55;
  font-size: 12px;
}

.external-auth-container-2 {
  width: 100%;
  color: #000;
  text-decoration: none;
}


