@import url(../../assets/css/normalize.css);
@import url(../../assets/css/webflow.css);
@import url(../../assets/css/ibl-ai-analytics-pro-v2.webflow.css);
@import url(../../assets/css/custom.css);
@import url(https://drive.google.com/uc?export=view&id=1DZ1X4oaKbcEoicQQ7AMublzIQKQhL2ZZ);

/*COPILOT MENTOR RELATED CSS*/
/*TODO TO BE BUNDLED ALONG SIDE WITH THE COPILOT COMPONENT*/
.af-view .chat-box.logged-in.hidden {
  display: flex !important;
}
.af-view .webpage-container{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    min-width: auto;
    overflow: auto;
    padding-right: 380px;
}

.af-view .fullpage-container{
    display: flex !important;
}

.right-content{
    overflow: hidden;
    flex: none;
    padding: unset!important;
    display: flex!important;
    flex-direction: column!important;
    width: 380px;
    bottom: 0;
    left: auto;
    position: fixed;
    right: 0;
    top: 67px;
    z-index: 99;
    border-left: 0.4px solid #6d6d6d !important;
}
.chat-box{
    border: none!important;
    width: auto!important;
    flex-grow: 1;
    height: auto!important;
    border-radius: 6px 6px 0 0;
    bottom: 0;
    flex-direction: column;
    margin-right: 1%;
    position: relative;
    right: 0;
    top: auto;
    z-index: 99;
    display: flex!important;
}
.div-block-549{
    height: 100%;
    width: 100%;
}
.af-view .html-embed-15{
    height: 100%;
}
.right-content .flex-block-2{
    display: none;
}
.prof_menu_popup.open{
    display: block!important;
}
.prof_menu_popup .link-2-user_info{
    overflow: auto;
}
@media screen and (max-width: 480px) {
    .af-view .webpage-container.no-padding-mobile{
        padding-right:unset;
    }
    .body .right-content{
        display: none!important;
    }
    .body .right-content.show-mentor{
        display: flex!important;
        width: 100%!important;
        z-index: 99999;
        top: 0px;
    }

}
.af-view .mentor-closer-block{
    background-color: #21252a;
    background-image: linear-gradient(225deg, #21252a, rgba(255, 255, 255, .66));
}
.af-view .mentor-closer-block {
    top: auto;
    bottom: 0%;
    left: 50%;
    right: auto;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}